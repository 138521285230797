@import "settings";

html {
  font-size: 16px !important;
}

#root {
  min-height: 100vh;
}

.ReactModal__Overlay {
  z-index: 100;
}
